@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .darker-bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0 0);
  }
  .slides {
  }
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px black;
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #1e293b;
}
